/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Tappable from 'react-tappable';
import { useLocation, withRouter } from 'react-router-dom';
import { songChoiceSongs } from '../../../utils/reactiveVars';
import ContentHereSquare from '../ContentHereSquare';
import SongItem from './SongItem';

const SongCards = ({
  title,
  songs,
  pageTitle,
  name,
  type,
  signingVideos,
  showContentHereImg,
  history,
  filterParams,
  seeAllRoute,
}) => {
  if ((!songs || songs?.length < 1) && !showContentHereImg) return null;
  const location = useLocation();

  // eslint-disable-next-line operator-linebreak
  const linkLocation =
    type === 'Recommended' ? '/recommended-songs' : '/song-choice';

  return (
    <>
      <div className="card-section-header mb-20 px-3 px-xl-0">
        <p className="heading__section text-uppercase ">{title}</p>
        <Tappable
          component="a"
          className="text__white text__fs-10 mr-3"
          onTap={() => {
            songChoiceSongs({
              path: location.pathname,
              pageTitle,
              name,
              type,
              songs,
              filterParams,
            });
            history.push(seeAllRoute || linkLocation);
          }}
        >
          SEE ALL
        </Tappable>
      </div>
      <div className="px-xl-0 px-3">
        <div className="row mb-20">
          {songs?.slice(0, 6).map(({ title: songTitle, id, albumArt }) => (
            <SongItem
              title={songTitle}
              key={id}
              id={id}
              albumArt={albumArt}
              songs={songs}
              signingVideos={!!signingVideos}
            />
          ))}
          {showContentHereImg && <ContentHereSquare />}
        </div>
      </div>
    </>
  );
};

export default withRouter(SongCards);
