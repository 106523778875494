import { gql } from '@apollo/client';

export const PlayerHitFieldsFragment = gql`
  fragment PlayerHitFields on PlayerStat {
    id
    sessionUUID
    songId
    hitUUID
    audioId
    updateExpected
    updateSequence
    playheadPositionStartMs
    playheadPositionEndMs
    shareId
    esongbookId
    organisationRef
  }
`;
