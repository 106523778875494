/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable eqeqeq */
import React from 'react';
import { Link } from 'react-router-dom';
import DownloadsAndAboutDropdown from '../../Resources/DownloadsAndAboutDropdown';
import Bookmark from '../Bookmark';

const Header = ({ lessonPlan, resource }) => {
  const year =
    lessonPlan.step.term.year.year == 0
      ? 'Reception'
      : lessonPlan.step.term.year.year;

  const {
    step: {
      term: { term },
    },
  } = lessonPlan;

  const hasDownloadbles = JSON.parse(resource?.downloadables)?.length > 0;
  const stat = {
    lesson_plan_id: lessonPlan.id,
    event_type: 'OpenedLessonPlanPDF',
  };

  return (
    <>
      <div className="main__container bg__cello mt-0 mb-4">
        <div className="resource__header">
          <div className="resource__header__grid curriculum-grid">
            <div>
              <div href="" className="cp__header__breadcrumb">
                <b>Music Curriculum </b>&nbsp;{' '}
                <Link to={`/music-curriculum/${year}`} className="text-light">
                  <u>Year {year}</u>
                </Link>{' '}
                &nbsp;/ &nbsp;
                <Link
                  to={`/music-curriculum/${year}/${term}`}
                  className="text-light"
                >
                  <u>Term {term}</u>
                </Link>{' '}
                &nbsp;/ &nbsp;Step {lessonPlan.step.step}
              </div>

              <Bookmark
                lessonPlanId={lessonPlan.id}
                bookmarkId={lessonPlan.bookmarkId}
                hasBeenBookmarked={lessonPlan.hasBeenBookmarked}
              />

              <div className="cp__header__info">
                <h2 className="cp__header__title">
                  <span className="d-none d-md-inline">Lesson Plan</span>{' '}
                  <span className="text__fw-600">{lessonPlan.title}</span>
                </h2>
              </div>
            </div>
            {hasDownloadbles && (
              <DownloadsAndAboutDropdown
                resource={resource}
                buttonText="Additional Printable Resources"
                stat={stat}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
