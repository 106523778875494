/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Banners from '../components/Home/Banners/Index';
import Carousel from '../components/Home/Carousel/Index';
import ResourcesCarousel from '../components/Home/ResourcesCarousel/Index';
import Categories from '../components/Home/Categories/Index';
import SongCards from '../components/Shared/SongCards/Index';
import withPageWrapper from '../components/Shared/HOCs/withPageWrapper';
import useGetRecommendedSongs from '../hooks/Song/useGetRecommendedSongs';
import Loading from '../components/Shared/Loading';
import NewSparks from '../components/Home/NewSparks';
import RecommendedPlaylists from '../components/Home/RecommendedPlaylists';

const Index = () => {
  // const { data } = useGetSongs(2000, {
  //   hasSongDetail: { column: 'RECOMMENDED', value: true },
  //   available: true,
  // });
  const { data, loading } = useGetRecommendedSongs(6, {
    randomise: true,
  });

  return (
    <>
      <section>
        <div className="main__container">
          <Carousel />
          {loading && <Loading />}
        </div>
        <NewSparks />
        <div className="main__container">
          <SongCards
            songs={data?.recommendedSongs?.data}
            title="Recommended Songs"
            pageTitle="Recommended Songs"
            name="Recommended"
            type="Recommended"
          />
          <RecommendedPlaylists />
          <ResourcesCarousel />
          <Categories />
          <Banners />
          <SongCards title="Recommended songbooks" />
        </div>
      </section>
    </>
  );
};

export default withPageWrapper({ pageTitle: 'Home | Sparkyard' })(Index);
