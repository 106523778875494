/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Tappable from 'react-tappable';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

const SearchBarDropdown = ({ history }) => {
  const { handleSubmit, register } = useForm();
  const handleSearch = (data) =>
    history.push(`/search?text=${encodeURIComponent(data.text)}`);

  return (
    <div className="searchbar__dropdown d-none">
      <form
        autoComplete="off"
        className="searchbar__mobile d-none"
        onSubmit={handleSubmit(handleSearch)}
      >
        <input
          name="text"
          type="text"
          className="searchbar__mobile__input"
          placeholder="Search"
          ref={register}
        />
      </form>
      <p className="heading__section">Categories</p>
      <div className="row category">
        <div className="col-xl-4 col-6">
          <Tappable
            component="a"
            className="category__thumbnail one"
            onTap={() => {
              history.push('/assemblies');
            }}
          >
            <h3 className="category__thumbnail__heading">ASSEMBLIES</h3>
            <img
              src="/img/assembly-img.png"
              alt=""
              className="category__thumbnail__img"
            />
          </Tappable>
        </div>
        <div className="col-xl-4 col-6">
          <Tappable
            component="a"
            className="category__thumbnail two"
            onTap={() => {
              history.push('/sing-in-every-subject');
            }}
          >
            <h3 className="category__thumbnail__heading">
              Sing in <br /> Every <br /> Subject
            </h3>
            <img
              src="/img/sing-subject-img.png"
              alt=""
              className="category__thumbnail__img"
            />
          </Tappable>
        </div>
        <div className="col-xl-4 col-6">
          <Tappable
            component="a"
            className="category__thumbnail three"
            onTap={() => {
              history.push('/school-routines-and-signals');
            }}
          >
            <h3 className="category__thumbnail__heading">
              School <br /> Routines <br /> & signals
            </h3>
            <img
              src="/img/school-routine-img.png"
              alt=""
              className="category__thumbnail__img"
            />
          </Tappable>
        </div>
        <div className="col-xl-4 col-6">
          <Tappable
            component="a"
            className="category__thumbnail four"
            onTap={() => {
              history.push('/sing-for-fun');
            }}
          >
            <h3 className="category__thumbnail__heading">
              Sing <br /> for fun
            </h3>
            <img
              src="/img/sing-fun-img.png"
              alt=""
              className="category__thumbnail__img"
            />
          </Tappable>
        </div>
        <div className="col-xl-4 col-6">
          <Tappable
            component="a"
            className="category__thumbnail five"
            onTap={() => {
              history.push('/choirs-and-concerts');
            }}
          >
            <h3 className="category__thumbnail__heading">
              Choirs & <br /> Concerts
            </h3>
            <img
              src="/img/choirs-concert-img.png"
              alt=""
              className="category__thumbnail__img"
            />
          </Tappable>
        </div>
        <div className="col-xl-4 col-6">
          <Tappable
            component="a"
            className="category__thumbnail six"
            onTap={() => {
              history.push('/sing-in-the-community');
            }}
          >
            <h3 className="category__thumbnail__heading">
              Sing in the <br /> community
            </h3>
            <img
              src="/img/sing-community-img.png"
              alt=""
              className="category__thumbnail__img"
            />
          </Tappable>
        </div>
        <div className="col-xl-4 col-6 ml-xl-auto">
          <Tappable
            component="a"
            className="category__thumbnail seven"
            onTap={() => {
              history.push('/wellbeing');
            }}
          >
            <h3 className="category__thumbnail__heading">Wellbeing</h3>
            <img
              src="/img/wellbeing-img.png"
              alt=""
              className="category__thumbnail__img"
            />
          </Tappable>
        </div>
        <div className="col-xl-4 col-6 mr-xl-auto">
          <Tappable
            component="a"
            className="category__thumbnail eight"
            onTap={() => {
              history.push('/collective-worship');
            }}
          >
            <h3 className="category__thumbnail__heading">
              Collective <br /> Worship
            </h3>
            <img
              src="/img/collective-worship-img.png"
              alt=""
              className="category__thumbnail__img"
            />
          </Tappable>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SearchBarDropdown);
