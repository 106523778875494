/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import useGetHomepageCarousel from '../../../hooks/Others/useGetHomepageCarousel';
import LoadJquery from '../../Shared/LoadJquery';

const Carousel = () => {
  const { parsedData } = useGetHomepageCarousel();

  return (
    <>
      <header className="header">
        <div
          id="homepageCarousel"
          className="carousel slide"
          data-ride="carousel"
          data-interval={10000}
        >
          <div className="carousel-inner">
            {parsedData?.map((image, index) => (
              <div
                className={`header__carousel carousel-item ${
                  index === 0 ? 'active' : ''
                }`}
                key={image.key}
              >
                <div className="header__carousel__item">
                  <a href={image.attributes.url}>
                    <img
                      src={image.attributes.image}
                      className="header__carousel__bg img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="carousel-indicators header__carousel__controls">
            {parsedData?.map((image, index) => (
              <a
                href=""
                data-target="#homepageCarousel"
                data-slide-to={index}
                className={index === 0 ? 'active' : ''}
                key={image.key}
              />
            ))}
          </div>
        </div>
      </header>
      <LoadJquery />
    </>
  );
};

export default Carousel;
