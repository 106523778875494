import { Switch, Route } from 'react-router-dom';
import React from 'react';
import Index from '../views/Index';
import Resource from '../views/Resource/Resource';
import Song from '../views/Song/Song';
import Assemblies from '../views/CategoryPages/Assemblies';
import Songs from '../views/Song/Songs';
import NotFound from '../views/Others/404';
import AssemblyPlans from '../views/Assembly/AssemblyPlans';
import Assembly from '../views/Assembly/Assembly';
import MusicCurriculum from '../views/MusicCurriculum/MusicCurriculum';
import CurriculumPlan from '../views/MusicCurriculum/CurriculumPlan';
import ChoirsAndConcerts from '../views/CategoryPages/ChoirsAndConcerts';
import CollectiveWorship from '../views/CategoryPages/CollectiveWorship';
import Wellbeing from '../views/CategoryPages/Wellbeing';
import TreatBox from '../views/TreatBox/TreatBox';
import SingInTheCommunity from '../views/CategoryPages/SingInTheCommunity';
import AssembliesTheme from '../views/Assembly/AssembliesTheme';
import RecommendedResources from '../views/Resource/RecommendedResources';
import SingInEverySubject from '../views/CategoryPages/SingInEverySubject';
import SchoolRoutinesAndSignals from '../views/CategoryPages/SchoolRoutinesAndSignals';
import SingForFun from '../views/CategoryPages/SingForFun';
import Collections from '../views/Collection/Collections';
import SongChoice from '../views/Song/SongChoice';
import SongsPlaylists from '../views/Playlist/SongsPlaylists';
import SongCalendar from '../views/SongCalendar/SongCalendar';
import Event from '../views/SongCalendar/Event';
import Collection from '../views/Collection/Collection';
import Playlist from '../views/Playlist/Playlist';
import CurriculumResources from '../views/MusicCurriculum/CurriculumResources';
import Login from '../views/Auth/Login';
import ResourcesFavourite from '../views/Resource/ResourcesFavourite';
import SongsFavourite from '../views/Song/SongsFavourite';
import SeeAll from '../views/Resource/SeeAll';
import SeeAllPlaylists from '../views/Playlist/SeeAll';
import SeeAllVideos from '../views/Others/SeeAllVideos';
import SeeAllSongbooks from '../views/Others/SeeAllSongbooks';
import NativitiesAndMusicals from '../views/Song/NativitiesAndMusicals';
import Search from '../views/Search/Search';
import Songbook from '../views/Songbook/Songbook';
import Logout from '../views/Auth/Logout';
import SeeAllAssemblies from '../views/Others/SeeAllAssemblies';
import ManageAccount from '../views/ManageAccount/ManageAccount';
import RecommendedSongs from '../views/Song/RecommendedSongs';
import SeeAllResources from '../views/Collection/SeeAllResources';
import Restricted from '../views/Others/Restricted';
import SharedSong from '../views/Share/SharedSong';
import SharedPlaylist from '../views/Share/SharedPlaylist/Index';
import ShareGuest from '../views/Share/ShareGuest';
import SharedCollection from '../views/Share/SharedCollection/Index';
import Songbooks from '../views/Songbook/Songbooks';
import SeeAllSongs from '../components/Collection/SeeAllSongs';
import Notifications from '../views/Notifications/Index';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={Index} />
    <Route path="/resources/:slug" exact component={Resource} />
    <Route
      path="/recommended-resources"
      exact
      component={RecommendedResources}
    />
    <Route path="/favourite-resources" exact component={ResourcesFavourite} />
    <Route path="/assemblies" exact component={Assemblies} />
    <Route path="/assembly-plans" exact component={AssemblyPlans} />
    <Route path="/assembly/:id/:slug?" exact component={Assembly} />
    <Route
      path="/assemblies/theme/:theme/:ageRange?"
      exact
      component={AssembliesTheme}
    />
    <Route
      path="/assemblies/age-range/:ageRange"
      exact
      component={AssembliesTheme}
    />
    <Route
      path="/music-curriculum/:year?/:term?"
      exact
      component={MusicCurriculum}
    />
    <Route
      path="/music-curriculum-plan/year/:year/term/:term/step/:step/lesson-plan/:id"
      component={CurriculumPlan}
      exact
    />
    <Route path="/choirs-and-concerts" component={ChoirsAndConcerts} />
    <Route path="/collective-worship" component={CollectiveWorship} />
    <Route path="/wellbeing" component={Wellbeing} />
    <Route path="/treat-box" component={TreatBox} />
    <Route path="/sing-in-the-community" component={SingInTheCommunity} />
    <Route path="/sing-in-every-subject" exact component={SingInEverySubject} />
    <Route
      path="/school-routines-and-signals"
      exact
      component={SchoolRoutinesAndSignals}
    />
    <Route path="/sing-for-fun" exact component={SingForFun} />
    <Route path="/collections" exact component={Collections} />
    <Route path="/collection/:id" exact component={Collection} />
    <Route
      path="/collection/resources/see-all"
      exact
      component={SeeAllResources}
    />
    <Route path="/collection/songs/see-all" exact component={SeeAllSongs} />
    <Route path="/song/:id" exact component={Song} />
    <Route path="/songs" exact component={Songs} />
    <Route path="/song-choice" exact component={SongChoice} />
    <Route path="/songs/playlists" exact component={SongsPlaylists} />
    <Route path="/songs/playlists/:id" exact component={Playlist} />
    <Route path="/recommended-songs" exact component={RecommendedSongs} />
    <Route path="/songbook/:id" exact component={Songbook} />
    <Route path="/songs/all-songbooks" exact component={Songbooks} />
    <Route
      path="/songs/nativities-and-musicals"
      exact
      component={NativitiesAndMusicals}
    />
    <Route path="/favourite-songs" exact component={SongsFavourite} />
    <Route path="/song-calendar" exact component={SongCalendar} />
    <Route path="/event/:slug" exact component={Event} />
    <Route path="/curriculum-resources" exact component={CurriculumResources} />
    <Route path="/login-oota" exact component={Login} />
    <Route path="/see-all/resources" exact component={SeeAll} />
    <Route path="/see-all/playlists" exact component={SeeAllPlaylists} />
    <Route path="/see-all/videos" exact component={SeeAllVideos} />
    <Route path="/see-all/songbooks" exact component={SeeAllSongbooks} />
    <Route path="/see-all/assemblies" exact component={SeeAllAssemblies} />
    <Route path="/search" exact component={Search} />
    <Route path="/manage-account" exact component={ManageAccount} />
    <Route path="/restricted" exact component={Restricted} />
    <Route path="/shared/song/:songId/:uuid" exact component={SharedSong} />
    <Route
      path="/shared/playlist/:playlistId/:uuid"
      exact
      component={SharedPlaylist}
    />
    <Route
      path="/shared/collection/:collectionId/:uuid"
      exact
      component={SharedCollection}
    />
    <Route path="/notifications" exact component={Notifications} />

    <Route path="/guest/:type" component={ShareGuest} />
    <Route path="/signout" exact component={Logout} />
    <Route path="/404" component={NotFound} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
