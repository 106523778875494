import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';

const Banners = ({ history }) => (
  <div className="banner__section mt-5">
    <div className="row">
      <div className="col-md-6">
        <Tappable
          component="a"
          className="banner bg__pumpkin"
          onTap={() => history.push('/music-curriculum')}
        >
          <h5 className="banner__heading">Music Curriculum</h5>
          <p className="banner__text">
            A complete music curriculum with songs, playlists and resources for
            your whole school.
          </p>
          <img
            src="/img/music-curriculum.svg"
            className="banner__bg pr-2"
            alt=""
          />
        </Tappable>
      </div>
      <div className="col-md-6">
        <Tappable
          component="a"
          className="banner bg__fuchsia"
          onTap={() => history.push('/assembly-plans')}
        >
          <h5 className="banner__heading">Assembly plans</h5>
          <p className="banner__text">
            Ready-to-go assembly plans for the whole school year.
          </p>
          <img src="/img/assembly-plans.svg" className="banner__bg" alt="" />
        </Tappable>
      </div>
    </div>
  </div>
);

export default withRouter(Banners);
