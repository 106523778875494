import React from 'react';
import { Link } from 'react-router-dom';

const SectionTitleWithButton = ({ title, to, onClick }) => (
  <div className="d-flex align-items-center justify-content-between mb-20 px-xl-0 px-3">
    <p className="heading__section text-uppercase">{title}</p>
    <Link className="text__white text__fs-10" to={to} onClick={onClick}>
      SEE ALL
    </Link>
  </div>
);

export default SectionTitleWithButton;
