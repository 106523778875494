/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import useStores from '../../../player/hooks/useStores';
import { albumArtDefault } from '../../../utils/helpers';
import PlaySong from '../PlaySong';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SongItem = ({
  title,
  id,
  albumArt,
  songs = [],
  signingVideos,
  history,
}) => {
  const { mediaPlayerStore } = useStores();
  const songLink = `/song/${id}`;

  return (
    <div className="col-lg-2 col-md-4 col-6">
      <div className="song__details">
        <div className="song__thumbnail">
          {signingVideos ? (
            <Tappable component="a" onTap={() => history.push(songLink)}>
              <img
                className="song__thumbnail__img"
                src={albumArt || albumArtDefault}
                alt={title}
              />
            </Tappable>
          ) : (
            <>
              <img
                className="song__thumbnail__img"
                src={albumArt || albumArtDefault}
                alt={title}
              />
              <Tappable
                component="div"
                onTap={() => mediaPlayerStore.setPlaylist(songs)}
              >
                <PlaySong id={id} />
              </Tappable>
            </>
          )}
        </div>
        <Tappable
          component="a"
          className="song__title text-white"
          dangerouslySetInnerHTML={{ __html: title }}
          onTap={() => history.push(songLink)}
        />
      </div>
    </div>
  );
};

export default withRouter(SongItem);
