import React from 'react';
import MainNav from './Sections/MainNav/MainNav';
import MainNavMobile from './Sections/MainNav/MainNavMobile';
import SearchBarDropdown from './Sections/SearchBarDropdown';

/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = () => (
  <>
    <MainNav />
    <MainNavMobile />
    <SearchBarDropdown />
  </>
);

export default Header;
