/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useRef, useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import 'styled-components/macro';
import ReactPlayer from 'react-player';
import Decimal from 'decimal.js';
import { useObserver } from 'mobx-react-lite';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import Song from './Song';
import useStores from '../hooks/useStores';
import { ProgressBar } from './ProgressBar';
import { SongControls } from './SongControls';
import {
  isMobileFullscreen,
  isPlayerFullscreen,
  organisationLoggedInAs,
} from '../../utils/reactiveVars';
import useSongPage from '../hooks/useSongPage';
import LikeAndAddIcons from '../../components/Shared/LikeAddIcons';
import VolumeSlider from './VolumeSlider';
import Progress from './Progress';
import useMediaQuery from '../../hooks/Others/useMediaQuery';
import MobileFullscreenDetails from './MobileFullscreen/Index';
import usePlayerHit from '../../hooks/Others/usePlayerHit';
import useAddToFavourites from '../../hooks/Favourite/useAddToFavourites';
import useRemoveFromFavourites from '../../hooks/Favourite/useRemoveFromFavourites';
import PlayerContainer from './Styles/PlayerContainer';
import { GridMediaButton } from './Styles/GridMediaButton';
import Queue from '../../utils/Queue';
import { PlayerHit } from '../types';
import useGetSongForPlayer from '../../hooks/Song/useGetSongForPlayer';

declare const Modernizr: any;

const Controls = styled('div')<{
  isIOS?: boolean;
}>`
  ${tw`h-full grid`}
  display: -ms-grid;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  grid-template-columns: 1.5fr 1.1fr 0.8fr 7.2fr 0.4fr 0.4fr 0.3fr;
  -ms-grid-columns: 1.7fr 2.5fr 1fr 1fr 1.5fr 1.75fr 0.25fr 0.25fr 0.4fr;

  @media (min-width: 429px) and (max-width: 768px) {
    grid-template-columns: 0.5fr 1.3fr 1.1fr 4fr 0.4fr 0.4fr 0.5fr;
  }
  @media (min-width: 769px) and (max-width: 1023px) {
    grid-template-columns: ${({ isIOS }) =>
        isIOS ? '1.2fr 0.01fr 1fr' : '1.5 1.1fr 0.8'} 4fr 0.4fr 0.4fr 0.3fr;
  }
  @media (min-width: 1024px) and (max-width: 1365px) {
    grid-template-columns: 1.2fr ${({ isIOS }) =>
        isIOS
          ? '0.01fr 1.2fr 5.2fr 0.6fr 0.3fr'
          : '1.1fr 1.2fr 5.9fr 0.4fr 0.4fr'} 0.3fr;
  }
  @media (max-width: 767px),
    screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    grid-template-columns: 1fr 4.5fr 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    max-width: 414px;
    background-color: #293943 !important;
  }
  @media screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
    height: 86px;
  }
`;

type PlayerProps = {
  player: React.RefObject<ReactPlayer>;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
};
type Timer = undefined | number | any;
const Player = ({ player, refetch }: PlayerProps) => {
  const { mediaPlayerStore } = useStores();

  useEffect(() => {
    let timer: Timer, timer2: Timer;

    if (mediaPlayerStore.jumpTo.playSinglePhrase) {
      timer = window.setTimeout(() => {
        mediaPlayerStore.resetJumpTo();
        mediaPlayerStore.setPlaying(false);
      }, mediaPlayerStore.jumpTo.phraseEnds - mediaPlayerStore.jumpTo.phraseStarts);
    }
    if (mediaPlayerStore.jumpTo.phraseStarts > 0) {
      player.current?.seekTo(
        +new Decimal(mediaPlayerStore.jumpTo.phraseStarts).dividedBy(1000)
      );
      mediaPlayerStore.setSeeking(false);
    }

    return () => {
      if (timer) clearTimeout(timer);
      // if (timer2) clearTimeout(timer2);
    };
  }, [mediaPlayerStore.jumpTo, mediaPlayerStore, player]);

  const { createPlayerHitRequest, updatePlayerHitRequest } = usePlayerHit();

  const {
    progress: { playedSeconds },
    playerHit,
    setPlayerHit,
    lastPlayheadPositionMs,
    currentPlayheadPositionMs,
    song: { id: songId },
  } = mediaPlayerStore;

  const organisationRef = organisationLoggedInAs()?.id;
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState(0);

  const roundedPlayedSeconds = Math.round(playedSeconds);

  // Increment counter for automatatic hit updates every ten seconds
  useEffect(() => {
    if (roundedPlayedSeconds !== 0 && mediaPlayerStore.playing) {
      setTimeSinceLastUpdate((prevState) => prevState + 1);
    }
  }, [roundedPlayedSeconds]);

  // Update last hit automatically after ten seconds of playback
  useEffect(() => {
    if (timeSinceLastUpdate >= 10) {
      const { hitUUID } = playerHit;

      const playerHitObj = {
        ...playerHit,
        updateExpected: true,
        updateSequence: playerHit.updateSequence + 1,
        playheadPositionEndMs: Math.round(playedSeconds * 1000),
      };
      const { updateExpected, updateSequence, playheadPositionEndMs } =
        playerHitObj;

      setPlayerHit(playerHitObj);

      Queue.enqueue(() =>
        updatePlayerHitRequest({
          updateExpected,
          updateSequence,
          playheadPositionEndMs,
          hitUUID,
        })
      );
      setTimeSinceLastUpdate(0);
    }
  });

  const currentAudioId = mediaPlayerStore.song.audios.find(
    (audio) => audio.files.mp3 === mediaPlayerStore.currentSrc
  )?.id;

  return useObserver(() => (
    <ReactPlayer
      ref={player}
      config={{ file: { forceAudio: true } }}
      url={mediaPlayerStore.currentSrc}
      playing={mediaPlayerStore.playing}
      height={0}
      width={0}
      progressInterval={100}
      onProgress={(e) => {
        if (!mediaPlayerStore.seeking) mediaPlayerStore.setProgress(e);
      }}
      onDuration={(e) => mediaPlayerStore.setDuration(e)}
      volume={mediaPlayerStore.volume}
      onPlay={() => {
        if (
          mediaPlayerStore.shouldRecordHit &&
          !mediaPlayerStore.seeking &&
          playerHit.songId
        ) {
          const playerHitObj: PlayerHit = {
            ...playerHit,
            hitUUID: uuidv4(),
            playheadPositionStartMs: currentPlayheadPositionMs,
            playheadPositionEndMs: null,
            updateExpected: true,
            updateSequence: 0,
            organisationRef,
            songId,
            sessionUUID: playerHit.sessionUUID || uuidv4(),
          };

          delete playerHitObj.id;

          setPlayerHit(playerHitObj);

          while (
            !uuidValidate(playerHitObj.hitUUID) ||
            !uuidValidate(playerHitObj.sessionUUID)
          ) {
            playerHitObj.hitUUID = uuidv4();
            if (!uuidValidate(playerHitObj.sessionUUID)) {
              playerHitObj.sessionUUID = uuidv4();
            }
            setPlayerHit(playerHitObj);
          }

          Queue.enqueue(() => createPlayerHitRequest(playerHitObj));

          setTimeSinceLastUpdate(0);
        }

        mediaPlayerStore.setPlaying(true);
        // mediaPlayerStore.setShouldRecordHit(true);
      }}
      onPause={() => {
        const { hitUUID } = playerHit;

        const playerHitObj = {
          ...playerHit,
          updateExpected: false,
          updateSequence: playerHit.updateSequence + 1,
          playheadPositionEndMs: Math.round(playedSeconds * 1000),
        };
        const { updateExpected, updateSequence, playheadPositionEndMs } =
          playerHitObj;

        setPlayerHit(playerHitObj);

        Queue.enqueue(() =>
          updatePlayerHitRequest({
            updateExpected,
            updateSequence,
            playheadPositionEndMs,
            hitUUID,
          })
        );
        setTimeSinceLastUpdate(0);

        mediaPlayerStore.setPlaying(false);
        mediaPlayerStore.setCurrentPlayheadPositionMs(
          Math.round(playedSeconds * 1000)
        );
      }}
      onSeek={() => {
        if (
          mediaPlayerStore.playing &&
          !mediaPlayerStore.seeking &&
          mediaPlayerStore.shouldUpdateHit
        ) {
          // mediaPlayerStore.setShouldRecordHit(true);

          Queue.enqueue(() =>
            updatePlayerHitRequest({
              updateExpected: false,
              updateSequence: playerHit.updateSequence + 1,
              playheadPositionEndMs: lastPlayheadPositionMs,
              hitUUID: playerHit.hitUUID,
            })
          );

          const playerHitObj = {
            ...playerHit,
            hitUUID: uuidv4(),
            playheadPositionStartMs: currentPlayheadPositionMs,
            playheadPositionEndMs: null,
            updateSequence: 0,
            organisationRef,
            audioId: currentAudioId,
            songId,
            sessionUUID: playerHit.sessionUUID || uuidv4(),
          };

          delete playerHitObj.id;

          setPlayerHit(playerHitObj);

          while (
            !uuidValidate(playerHitObj.hitUUID) ||
            !uuidValidate(playerHitObj.sessionUUID)
          ) {
            playerHitObj.hitUUID = uuidv4();
            if (!uuidValidate(playerHitObj.sessionUUID)) {
              playerHitObj.sessionUUID = uuidv4();
            }
            setPlayerHit(playerHitObj);
          }
          Queue.enqueue(() => createPlayerHitRequest(playerHitObj));

          setTimeSinceLastUpdate(0);
        }
      }}
      onError={() => {
        refetch({ id: songId, organisation_ref: organisationRef });
      }}
    />
  ));
};

const MediaPlayer = ({
  toggleFullScreen,
}: {
  toggleFullScreen: () => Promise<void>;
}): JSX.Element => {
  const player = useRef<ReactPlayer>(null!);
  const { mediaPlayerStore } = useStores();
  const [, setIconType] = useState<'Fullscreen' | 'Minimize'>('Fullscreen');

  const organisationRef = organisationLoggedInAs()?.id || null;

  const { getSongRequest, refetch } = useGetSongForPlayer(
    false,
    null,
    organisationRef
  );

  if (!mediaPlayerStore.showSlide) {
    document.body.style.overflow = 'visible';
    document.documentElement.style.overflow = 'visible';
  } else {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  }

  const isMobile = useMediaQuery(
    '(max-width: 767px), screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) '
  );
  const supportsFullscreen = () => Modernizr.fullscreen;

  const currentPage = useSongPage(
    mediaPlayerStore.song?.pages,
    +new Decimal(mediaPlayerStore.progress.playedSeconds).times(1000) // using decimal.js here cos js cannot do floating point operations well
  );

  const isEmptyLyrics = mediaPlayerStore.song?.pages.length < 1;

  const [favIconState, setFavIconState] = useState<'Favourite' | 'Favourited'>(
    'Favourite'
  );

  useEffect(() => {
    if (mediaPlayerStore.song.isFavourited) {
      return setFavIconState('Favourited');
    }
    return setFavIconState('Favourite');
  }, [mediaPlayerStore.song]);

  const {
    song: { id },
  } = mediaPlayerStore;

  const { addToFavouriteRequest } = useAddToFavourites('SONG', id);
  const { removeFromFavouritesRequest } = useRemoveFromFavourites('SONG', id);

  const isGuest = !!mediaPlayerStore.shareId;

  const userAgemt = navigator.userAgent;
  const isIOS =
    /iPad|iPhone|iPod/.test(userAgemt) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  return useObserver(() => (
    <>
      <div
        tw="bg-off-black w-full h-86 fixed bottom-0 text-white"
        className="z-100 h-m-73 media-player-bg"
      >
        <PlayerContainer>
          {/* <MediaBar> */}
          <ProgressBar player={player} />

          <Controls isIOS={isIOS}>
            <SongControls gridPosition={1} getSongRequest={getSongRequest} />
            {!isIOS && <VolumeSlider gridPosition={2} />}
            <Progress gridPosition={3} hideOnMobile />
            <Song gridPosition={4} gridPositionMobile={2} isGuest={isGuest} />
            <GridMediaButton
              icon={favIconState}
              gridPosition={5}
              gridPositionMobile={3}
              onClick={() => {
                if (favIconState === 'Favourite') {
                  addToFavouriteRequest.send();
                  return setFavIconState('Favourited');
                }
                removeFromFavouritesRequest.send();
                return setFavIconState('Favourite');
              }}
              disabled={isGuest}
            />
            <div
              className={`my-auto song-actions ${
                isGuest ? 'disable-element' : ''
              }`}
            >
              <LikeAndAddIcons
                headerText="Song options"
                modalId="songHeaderActions"
                favourite
                addToPlaylist
                addToCollection
                share
                itemType="SONG"
                id={mediaPlayerStore.song?.id}
              />{' '}
            </div>
            <GridMediaButton
              icon={isPlayerFullscreen() ? 'Minimize' : 'Fullscreen'}
              onClick={() => {
                mediaPlayerStore.setShowSlide(!mediaPlayerStore.showSlide);
                if (!isMobile && supportsFullscreen()) {
                  toggleFullScreen();
                }
                isPlayerFullscreen(!isPlayerFullscreen());
                if (isMobile && isPlayerFullscreen()) {
                  isMobileFullscreen(true);
                }
              }}
              gridPosition={7}
              gridPositionMobile={4}
              mobileDimensions={29}
              tabletDimensions={29}
              disabled={isEmptyLyrics || isGuest}
              pulsate={mediaPlayerStore.ready && !isPlayerFullscreen()}
            />
          </Controls>
        </PlayerContainer>
      </div>
      <MobileFullscreenDetails
        currentSlide={mediaPlayerStore.song.pages.indexOf(currentPage)}
        totalSlides={mediaPlayerStore.song.pages.length}
        player={player}
        setIconType={setIconType}
        isGuest={isGuest}
      />
      <Player player={player} refetch={refetch} />
    </>
  ));
};

export default MediaPlayer;
