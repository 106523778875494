import React from 'react';
import useGetRecommendedPlaylists from '../../hooks/Playlist/useGetRecommendedPlaylists';
import Loading from '../Shared/Loading';
import PlaylistCards from '../Shared/PlaylistCards';

const RecommendedPlaylists = () => {
  const { loading, data } = useGetRecommendedPlaylists();

  loading && <Loading />;

  return (
    <>
      {data?.recommendedPlaylists?.playlists && (
        <PlaylistCards
          playlists={data.recommendedPlaylists.playlists}
          title="Recommended Playlists"
        />
      )}
    </>
  );
};

export default RecommendedPlaylists;
