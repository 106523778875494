/* eslint-disable jsx-a11y/anchor-is-valid */
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import { defaultAvatarMid } from '../../../../../utils/objects';
import {
  isMobileFullscreen,
  isMobileSidebarOpen,
  user,
} from '../../../../../utils/reactiveVars';
import Notifications from './Notifications/Notifications';
import PopupInfo from '../../../PopupInfo';

const MainNavMobile = ({ history }) => {
  const mobileFullscreen = useReactiveVar(isMobileFullscreen);
  const { avatar } = useReactiveVar(user);

  const avatarSrc = avatar || defaultAvatarMid;

  return (
    <nav
      className={`main__nav__mobile ${
        mobileFullscreen ? 'header-nav fixed-top flip-element__no-height' : ''
      }`}
    >
      {!mobileFullscreen && (
        <Tappable
          component="a"
          className="main__nav__mobile__avatar mr-auto mr-md-0"
          id="sidebar-cta"
          onTap={(e) => {
            e.preventDefault();
            isMobileSidebarOpen(true);
            document.body.style.overflow = 'hidden';
          }}
        >
          <img src={avatarSrc} alt="" className="" />
        </Tappable>
      )}

      {mobileFullscreen ? (
        <div className="mx-auto">
          <PopupInfo position='bottom' isHeader={true} />
        </div>
      ) : (
        <Tappable
          component="a"
          className="main__nav__mobile__logo mx-auto"
          onTap={() => history.push('/')}
        >
          <img src="/img/logo-icon.svg" alt="" />
        </Tappable>
      )}

      {!mobileFullscreen && (
        <>
          <Notifications />
          <Tappable
            component="a"
            className="main__nav__mobile__search"
            id="search-dropdown-cta"
          >
            <img
              src="/img/search-icon-white.png"
              className="searchbar__icon-search"
              alt=""
            />
            <img
              src="/img/plus-icon-white.svg"
              className="searchbar__icon-cross d-none"
              alt=""
            />
          </Tappable>
        </>
      )}
    </nav>
  );
};

export default withRouter(MainNavMobile);
