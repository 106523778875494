import { useQuery } from '@apollo/client';
import { GET_RECOMMENDED_PLAYLISTS } from '../../graphql/queries/playlist/playlist';

const useGetRecommendedPlaylists = () => {
  const { data, loading } = useQuery(GET_RECOMMENDED_PLAYLISTS, {
    onError: () => {},
  });

  return { data, loading };
};

export default useGetRecommendedPlaylists;
