/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { useObserver } from 'mobx-react-lite';
import tw, { styled } from 'twin.macro';
import 'styled-components/macro';
import React from 'react';
import useStores from '../hooks/useStores';
import { formatTime } from './ProgressBar';

const ProgressContainer = styled.div<{
  gridPosition: number;
  hideOnMobile?: boolean;
}>`
  ${tw`h-full flex items-center`}
  ${({ gridPosition }) => gridPosition && `-ms-grid-column:  ${gridPosition}`};
  ${({ gridPosition }) => gridPosition && `grid-column:  ${gridPosition}`};
  ${({ hideOnMobile }) =>
    hideOnMobile &&
    `
    @media (max-width: 767px),screen and (min-width: 428px) and (max-width: 926px) and (orientation: landscape) {
      display: none;
    }`};
  font-size: 12px;
`;

const Progress = ({
  gridPosition,
  hideOnMobile,
}: {
  gridPosition: number;
  hideOnMobile?: boolean;
}): JSX.Element => {
  const { mediaPlayerStore } = useStores();

  return useObserver(() => (
    <ProgressContainer gridPosition={gridPosition} hideOnMobile={hideOnMobile}>
      <span>{formatTime(mediaPlayerStore.progress.playedSeconds)}</span>
      &nbsp;/&nbsp;
      <span>{formatTime(mediaPlayerStore.duration)}</span>
    </ProgressContainer>
  ));
};

export default Progress;
