/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Tappable from 'react-tappable';
import { withRouter } from 'react-router-dom';
import useGetRecommendedResources from '../../../hooks/Resource/useGetRecommendedResources';
import { getDescription } from '../../../utils/resource';
import LoadJquery from '../../Shared/LoadJquery';
import Tags from '../../ResourcesFavourite/Sections/Tags';

const ResourcesCarousel = ({ history }) => {
  const { data, loading } = useGetRecommendedResources(4);

  const classMap = {
    'SEND Resource': 'carousel__send_resource',
    'Creative Ideas': 'carousel__creative-ideas',
    'Vocal Pathway Activity': '',
    'Bright Idea': 'carousel__bright-idea',
    'Spotlight on...': 'carousel__spotlight',
    'Curriculum Link': 'carousel__curriculum-link',
    'Music Curriculum Activity': 'carousel__music-curriculum-activity',
    'Music Activity': 'carousel__music-activity',
    Blog: 'carousel__blog',
    Guide: 'carousel__guide',
  };

  return (
    <>
      <div className="resources__carousel">
        <div className="d-flex align-items-center justify-content-between mb-20">
          <p className="heading__section">Recommended resources</p>
          <Tappable
            component="a"
            className="text__white text__fs-10"
            onTap={() => history.push('/recommended-resources')}
          >
            SEE ALL
          </Tappable>
        </div>
        {loading && <div className="my-5 mx-auto">Loading...</div>}
        <div
          id="recommendedResourcesCarousel"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {data?.recommendedResources?.data?.map((resource, index) => (
              <div
                className={`carousel-item ${index === 0 ? 'active' : ''}`}
                key={resource.id}
              >
                <div className="carousel__container rounded-0">
                  <div
                    className={`carousel__container__img carousel__resource ${
                      classMap[resource.category.name]
                    }`}
                  >
                    <div className="text-center text__fs-24">
                      {resource.category.name.toUpperCase()}
                    </div>
                    {/* <img
                      src={resource.thumbnail}
                      alt={resource.title}
                      className=""
                    /> */}
                  </div>
                  <p className="carousel__container__title">
                    <Tappable
                      component="a"
                      style={{ color: '#000' }}
                      onTap={() => history.push(`/resources/${resource.slug}`)}
                    >
                      {`${resource.category.name.toUpperCase()}:  ${
                        resource.title
                      }`}
                    </Tappable>
                  </p>
                  <div className="carousel__container__desc">
                    {getDescription(resource)}
                    <Tags tags={resource.resourceTags} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="carousel-indicators carousel__controls">
            {data?.recommendedResources?.data?.map((resource, index) => (
              <a
                href=""
                className={index === 0 ? 'active' : ''}
                key={resource.id}
                data-slide-to={index}
                data-target="#recommendedResourcesCarousel"
              />
            ))}
          </div>
        </div>
      </div>
      <LoadJquery />
    </>
  );
};

export default withRouter(ResourcesCarousel);
