/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import useGetNewSparks from '../../hooks/Resource/useGetNewSparks';
import Loading from '../Shared/Loading';
import SectionTitleWithButton from '../Shared/SectionTitleWithButton';
import { resourceList } from '../../utils/reactiveVars';
import { shortenLongDescription } from '../../utils/helpers';

const NewSparks = () => {
  const { loading, data } = useGetNewSparks();

  loading && <Loading />;

  return (
    <div className="mb-5">
      <div className="main__container">
        <SectionTitleWithButton
          title="New sparks for you"
          to="/see-all/resources"
          onClick={() =>
            resourceList({
              resources: data?.newSparks?.resources,
              title: 'New sparks for you',
            })
          }
        />
      </div>
      <div className="main__container main__container--new-sparks mt-0">
        <div className="px-3 px-xl-0 pb-3">
          <div className="row">
            {data?.newSparks?.resources.slice(0, 4).map((resource) => (
              <div className="col-3" key={resource.id}>
                <Link to={`/resources/${resource.slug}`} className="hover-none">
                  <Card className="h-100 border__radius-10 border-0">
                    <img
                      className="resource-card-img"
                      alt="Sample"
                      src={resource.thumbnail}
                    />
                    <CardBody>
                      <CardTitle tag="h5" className="text-black mb-3 text-2xl">
                        {resource.title}
                      </CardTitle>
                      <CardText className="text-black text-xl">
                        {shortenLongDescription(resource.thumbnailText, 60)}
                      </CardText>
                    </CardBody>
                  </Card>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSparks;
