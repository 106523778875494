import { useQuery } from '@apollo/client';
import { GET_HOMEPAGE_CAROUSEL } from '../../graphql/queries/carousel/carousel';

const useGetHomepageCarousel = () => {
  const { data, loading, error } = useQuery(GET_HOMEPAGE_CAROUSEL);
  let parsedData;
  if (data?.homepageCarousel?.content) {
    parsedData = JSON.parse(data.homepageCarousel.content);
  }
  return { parsedData, loading, error };
};

export default useGetHomepageCarousel;
