import { gql } from '@apollo/client';
import { HomepageCarouselFieldsFragment } from './fragments';

export const GET_HOMEPAGE_CAROUSEL = gql`
  query HomepageCarousel {
    homepageCarousel {
      ...HomepageCarouselFields
    }
  }
  ${HomepageCarouselFieldsFragment}
`;
