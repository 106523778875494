/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/naming-convention */
import { useReactiveVar } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useUploadImage from '../../../../../hooks/Others/useUploadmage';
import useGetTag from '../../../../../hooks/useGetTag';
import useUpdateUser from '../../../../../hooks/User/useUpdateUser';
import { returnNumberRange } from '../../../../../utils/helpers';
import { availableRoles } from '../../../../../utils/objects';
import { user } from '../../../../../utils/reactiveVars';
import FormError from '../../../FormError';
import Avatar from './Avatar';
import ModalHeader from './ModalHeader';
import ResetPasswordButton from './ResetPasswordButton';
import SelectedTags from './SelectedTags';
import TagSuggestions from './TagSuggestions';

const EditAccountModal = () => {
  const {
    firstname,
    surname,
    email,
    roles,
    subjects,
    minAge,
    maxAge,
    avatar,
    categories,
    marketingConsent,
  } = useReactiveVar(user);

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [availableSubjects, setAvailableSubjects] = useState([]);

  const { register, handleSubmit, reset, errors } = useForm();
  const { updateUserRequest } = useUpdateUser();

  const { data: availableSubjectsData } = useGetTag('Curriculum Subject Links');

  useEffect(() => {
    setSelectedRoles(roles);
    setSelectedSubjects(subjects);
    if (availableSubjectsData?.tags) {
      const tagsFormatted = availableSubjectsData.tags.map(
        (tag) => tag.formattedName
      );
      setAvailableSubjects(tagsFormatted);
    }
  }, [roles, subjects, availableSubjectsData]);

  const defaultImage = avatar || '/img/big-avatar.png';
  const { onChange, img } = useUploadImage(defaultImage);

  const handleUpdateUser = (data) => {
    const { min_age, max_age } = data;

    const avatarHasChanged =
      defaultImage !== img.url && img.url !== '/img/big-avatar.png';

    const input = {
      min_age: parseInt(min_age, 10),
      max_age: parseInt(max_age, 10),
      firstname: data.firstname,
      surname: data.surname,
      email: data.email,
      roles: selectedRoles,
      subjects: selectedSubjects,
      marketing_consent: data.marketing_consent ? 1 : 0,
      categories,
      ...(avatarHasChanged && { avatar: img.file }),
    };

    updateUserRequest.send(input);
  };

  const handleTagSelect = (tag, tags, setTag, event) => {
    event.preventDefault();
    if (!tags.includes(tag)) {
      setTag([...tags, tag]);
    }
  };

  const handleRemoveTag = (tag, tags, setTag, event) => {
    event.preventDefault();
    const tagIndex = tags.indexOf(tag);
    if (tagIndex === -1) return;
    const tagsCopy = [...tags];
    tagsCopy.splice(tagIndex, 1);
    setTag(tagsCopy);
  };

  const tagTypeMap = {
    role: {
      values: selectedRoles,
      setValues: setSelectedRoles,
    },
    subject: {
      values: selectedSubjects,
      setValues: setSelectedSubjects,
    },
  };

  const actionMap = {
    ADD: handleTagSelect,
    REMOVE: handleRemoveTag,
  };

  const handleTagChange = (name, e, type, action) => {
    const { values, setValues } = tagTypeMap[type];
    actionMap[action](name, values, setValues, e);
  };

  return (
    <div
      className="modal fade"
      id="editAccountModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editAccountModal"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered custom__modal__edit-account"
        role="document"
      >
        <div className="modal-content custom__modal__edit-account__content">
          <ModalHeader />
          <div className="row">
            <Avatar onChange={onChange} img={img} />
            <div className="col mt-56 mt-md-0">
              <form className="manage-account text__fs-13">
                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="inputCity">First name</label>
                    <input
                      type="text"
                      className="form-control input__h-54"
                      defaultValue={firstname}
                      name="firstname"
                      ref={register({ required: true })}
                    />
                  </div>
                  {errors.firstname && <FormError text="required" />}

                  <div className="form-group col-md-5">
                    <label htmlFor="inputCity">Surname</label>
                    <input
                      type="text"
                      className="form-control input__h-54"
                      defaultValue={surname}
                      name="surname"
                      ref={register({ required: true })}
                    />
                  </div>
                  {errors.surname && <FormError text="required" />}

                  <ResetPasswordButton />
                </div>

                <div className="form-row mt-20 mt-md-0">
                  <div className="form-group col-md-8">
                    <label htmlFor="inputAddress">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      defaultValue={email}
                      ref={register({ required: true })}
                    />
                  </div>
                </div>
                {errors.email && <FormError text="required" />}

                {/* <div className="form-group">
                  <label htmlFor="inputAddress2">Role(s)</label>
                  <SelectedTags
                    selectedTags={selectedRoles}
                    handleChange={handleTagChange}
                    type="role"
                  />
                  <TagSuggestions
                    tags={availableRoles}
                    handleChange={handleTagChange}
                    type="role"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="inputAddress2">
                    Subjects you are interested in
                  </label>
                  <SelectedTags
                    selectedTags={selectedSubjects}
                    handleChange={handleTagChange}
                    type="subject"
                  />
                  <TagSuggestions
                    tags={availableSubjects}
                    handleChange={handleTagChange}
                    type="subject"
                  />
                </div> */}

                {/* <div className="mb-1">Age range you teach</div>
                <div className="form-row" style={{ marginBottom: '26px' }}>
                  <div className="form-group col-5 col-md-3">
                    <select
                      className="form-control form-control-lg"
                      name="min_age"
                      ref={register({ required: true })}
                      defaultValue={minAge}
                    >
                      {returnNumberRange(3, 13).map((num) => (
                        <option value={num} key={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-5 col-md-3">
                    <select
                      className="form-control form-control-lg"
                      name="max_age"
                      ref={register({ required: true })}
                      defaultValue={maxAge}
                    >
                      {returnNumberRange(4, 14).map((num) => (
                        <option value={num} key={num}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {(errors.min_age || errors.max_age) && (
                  <FormError text="required" />
                )} */}

                <div className="form-group checkbox__white">
                  <input
                    type="checkbox"
                    id="marketing_consent"
                    name="marketing_consent"
                    ref={register({ required: false })}
                    defaultChecked={marketingConsent}
                  />
                  <label htmlFor="marketing_consent" className="text-white">
                    Would you like to sign up to our Sparkyard email mailing
                    list to receive monthly tips, ideas and suggestions for
                    making the most out of your subscription? You can change
                    your settings here at any time.{' '}
                    <a href="https://www.sparkyard.com/privacy-notice">
                      Visit our Privacy Notice.
                    </a>
                  </label>
                </div>
              </form>
            </div>
          </div>

          <div className="mt-auto d-flex justify-content-between">
            <button
              onClick={() => {
                reset(user);
                setSelectedRoles([]);
                setSelectedSubjects([]);
              }}
              data-dismiss="modal"
              className="btn button__green-meadow button__w-144 button__transparent__green border__green"
            >
              Cancel
            </button>
            <button
              className="btn button__green-meadow button__w-144"
              onClick={handleSubmit(handleUpdateUser)}
            >
              Update details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAccountModal;
