import { useQuery } from '@apollo/client';
import { GET_NEW_SPARKS } from '../../graphql/queries/resource/resource';

const useGetNewSparks = () => {
  const { data, loading } = useQuery(GET_NEW_SPARKS, {
    onError: () => {},
  });

  return { data, loading };
};

export default useGetNewSparks;
